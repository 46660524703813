
import Header from '@/components/sections/Header.vue'
export default {
  name: 'Delete',
  layout: 'delete',
  scrollToTop: true,
  data () {
    return {
      email: '',
      errorData: null
    }
  },
  mounted () {
    localStorage.removeItem('sraEmail')
  },
  components: {
    Header
  },
  methods: {
    async sendEmail () {
      this.$store.dispatch('deleteAccount/sendOtp', this.email).then(
        (res) => {
          localStorage.setItem('sraEmail', this.email)
          this.$router.push('/delete-second-step')
        },
        (error) => {
          this.errorData = error.response.data.errors
        }
      )
    }
  }
}
