import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "delete" }
const _hoisted_2 = { class: "delete__wrap" }
const _hoisted_3 = { class: "delete__button-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "delete__title" }, "Here you can delete your SRA account", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("div", null, [
        _createElementVNode("div", { class: "delete__text" }, " Before proceeding, please be sure to review the Terms of Service regarding deletions. "),
        _createElementVNode("div", { class: "delete__text" }, " Deleting this account will delete all of its spaces and data. You cannot undo this action. ")
      ], -1)),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "delete__text-input" }, "Enter your email to confirm", -1)),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "email",
          placeholder: "Email",
          class: "delete__input",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.email) = $event))
        }, null, 512), [
          [_vModelText, $data.email]
        ])
      ]),
      _createElementVNode("div", {
        class: "delete__button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.sendEmail()))
      }, "Send code")
    ])
  ]))
}